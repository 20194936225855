import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';

import { EQRCodeStatus } from '@/constants/wechat';
import { fetchCheckQRCodeStatus, fetchQRCode } from '@/services/wechat';

export interface WeChatState {
	qrCode: {
		imgSrc: string;
		timestamp: string;
		expiresTime: number;
	};
	/** 二维码状态 */
	qrCodeStatus: EQRCodeStatus;
	/** wechat openId - login success. */
	weChatOpenId: string;
}

const initialState: WeChatState = {
	qrCode: {
		imgSrc: '',
		timestamp: '',
		expiresTime: 0
	},
	qrCodeStatus: EQRCodeStatus.WAITTING_SCAN,
	weChatOpenId: ''
};

export const getQRCode = createAsyncThunk(
	'wechat/QRCode',
	async (payload: { sceneStr: string; userId?: string }) => {
		const result = await fetchQRCode(payload);
		return result;
	}
);

export const getQRCodeStatus = createAsyncThunk(
	'wechat/QRCodeStatus',
	async (payload: {
		/** timestamp */
		id: string;
	}) => {
		const result = await fetchCheckQRCodeStatus(payload.id);
		return result;
	}
);

export const wechatSlice = createSlice({
	name: 'wechat',
	initialState,
	reducers: {
		resetQRCode: (state: WeChatState) => {
			state.qrCode = {
				imgSrc: '',
				timestamp: '',
				expiresTime: 0
			};
			state.qrCodeStatus = EQRCodeStatus.WAITTING_SCAN;
			state.weChatOpenId = '';
		}
	},
	extraReducers: builder => {
		builder.addCase(
			getQRCode.fulfilled,
			(state: WeChatState, action: PayloadAction<FetchWeChatQRCode>) => {
				if (action.payload.imgSrc) {
					state.qrCode = action.payload;
				}
			}
		);
		builder.addCase(
			getQRCodeStatus.fulfilled,
			(state: WeChatState, action: PayloadAction<FetchWeChatQRCodeStatus>) => {
				// 不是待扫描状态
				if (action.payload.qrCodeStatus !== EQRCodeStatus.WAITTING_SCAN) {
					state.qrCodeStatus = action.payload.qrCodeStatus as EQRCodeStatus;
					state.weChatOpenId = action.payload.weChatOpenId || '';
				}
			}
		);
	}
});

export const { resetQRCode } = wechatSlice.actions;

export const selectWeChatState = (state: RootState) => state.wechat;

export default wechatSlice.reducer;
