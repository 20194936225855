import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';

import { RootState } from '..';

import { LOCAL_STORAGE_CACHE_KEY } from '@/constants/common';
import { EStudyTeamProjectLevel } from '@/constants/studyTeam';
import { CREATE_STUDY_TEAM_DEFAULT_RULES } from '@/constants/text';

/** step交由studySlice管理 */
const initialState: StudyTeamCreation = {
	data: {
		name: '',
		teamSize: 1,
		startDate: null,
		projectName: '',
		description: '',
		rules: CREATE_STUDY_TEAM_DEFAULT_RULES,
		level: EStudyTeamProjectLevel.LEVEL2
	}
};

const DRAFT_EXPIRY_TIME = 30 * 60 * 1000; // 30分钟

const saveDraftToStorage = (state: StudyTeamCreation) => {
	const draftData: StudyTeamDraft = {
		data: state.data,
		timestamp: Date.now()
	};
	localStorage.setItem(LOCAL_STORAGE_CACHE_KEY.studyTeamDraft, JSON.stringify(draftData));
};

const loadDraftFromStorage = (): StudyTeamCreation | null => {
	const draftStr = localStorage.getItem(LOCAL_STORAGE_CACHE_KEY.studyTeamDraft);
	if (!draftStr) return null;

	try {
		const draft: StudyTeamDraft = JSON.parse(draftStr);
		const now = Date.now();

		if (now - draft.timestamp > DRAFT_EXPIRY_TIME) {
			localStorage.removeItem(LOCAL_STORAGE_CACHE_KEY.studyTeamDraft);
			return null;
		}

		return {
			data: draft.data
		};
	} catch {
		localStorage.removeItem(LOCAL_STORAGE_CACHE_KEY.studyTeamDraft);
		return null;
	}
};

const clearDraft = () => {
	localStorage.removeItem(LOCAL_STORAGE_CACHE_KEY.studyTeamDraft);
};

export const studyTeamSlice = createSlice({
	name: 'studyTeam',
	initialState,
	reducers: {
		patchStudyTeamData: (
			state: StudyTeamCreation,
			action: PayloadAction<Partial<StudyTeam>>
		) => {
			const patch = pickBy(action.payload, value => value !== undefined);
			state.data = { ...state.data, ...patch };
			saveDraftToStorage(state);
		},

		initStudyTeam: (state: StudyTeamCreation) => {
			const draft = loadDraftFromStorage();
			if (draft) {
				state.data = draft.data;
			}
		},

		clearStudyTeamData: (state: StudyTeamCreation) => {
			state.data = initialState.data;
			clearDraft();
		}
	}
});

export const { patchStudyTeamData, initStudyTeam, clearStudyTeamData } = studyTeamSlice.actions;

export const selectStudyTeamState = (state: RootState) => state.studyTeam;
export default studyTeamSlice.reducer;
