import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import applySlice from './slices/applySlice';
import enrollmentSlice from './slices/enrollmentSlice';
import feedSlice from './slices/feedSlice';
import onboardingSlice from './slices/onboardingSlice';
import socketSlice from './slices/socketSlice';
import studyProgramSlice from './slices/studyProgramSlice';
import studySlice from './slices/studySlice';
import studyTeamSlice from './slices/studyTeamSlice';
import userPreferenceSlice from './slices/userPreferenceSlice';
import userSlice from './slices/userSlice';
import wechatSlice from './slices/wechatSlice';

const rootReducer = combineReducers({
	user: userSlice,
	wechat: wechatSlice,
	enrollment: enrollmentSlice,
	apply: applySlice,
	socket: socketSlice,
	userPreference: userPreferenceSlice,
	feed: feedSlice,
	onboarding: onboardingSlice,
	study: studySlice,
	studyTeam: studyTeamSlice,
	studyProgram: studyProgramSlice
});

export const store = configureStore({
	reducer: rootReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
