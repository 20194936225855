import {
	PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_VALUE,
	PARAMS_STUDY_CENTER_EDIT_TEAM_VALUE
} from './searchParams';

export enum EStudyTeamProjectLevel {
	LEVEL1 = 'level1',
	LEVEL2 = 'level2',
	LEVEL3 = 'level3',
	LEVEL4 = 'level4'
}
export const StudyTeamProjectLevelText = {
	[EStudyTeamProjectLevel.LEVEL1]: 'Level One',
	[EStudyTeamProjectLevel.LEVEL2]: 'Level Two',
	[EStudyTeamProjectLevel.LEVEL3]: 'Level Three',
	[EStudyTeamProjectLevel.LEVEL4]: 'Level Four'
};

export const STUDY_TEAM_STEPS = [
	PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_VALUE.TEAM_BASIC_INFO,
	PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_VALUE.PROJECT_BASIC_INFO,
	PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_VALUE.PROJECT_DETAIL_INFO,
	PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_VALUE.TEAM_MEMBER
] as const;

export const TEAM_NAME_MAX = 20;
export const TEAM_SIZE_MIN = 3;
export const TEAM_SIZE_MAX = 30;
export const TEAM_PROJECT_NAME_MAX = 16;
export const TEAM_PROJECT_DESCRIPTION_MAX = 600;

export const EDIT_STUDY_TEAM_NAV_TEXT = {
	[PARAMS_STUDY_CENTER_EDIT_TEAM_VALUE.TEAM_DETAIL]: '组队信息',
	[PARAMS_STUDY_CENTER_EDIT_TEAM_VALUE.MEMBERS]: '成员管理'
};

export enum EStudyTeamMemberRole {
	MANAGER = 'manager',
	LEADER = 'leader',
	MEMBER = 'member'
}

export enum EStudyTeamMemberStatus {
	ACTIVE = 'active',
	PENDING = 'pending',
	REJECTED = 'rejected'
}

export enum ESkillItemType {
	CATEGORY = 'category',
	SKILL = 'skill'
}

export const SKILL_ITEMS: SkillItem[] = [
	{
		name: '前端开发',
		type: ESkillItemType.CATEGORY,
		skills: [{ name: 'HTML/CSS' }, { name: 'JavaScript' }]
	},
	{
		name: '前端框架',
		type: ESkillItemType.CATEGORY,
		skills: [{ name: 'React' }, { name: '其他（Angular，Vue等）' }]
	},
	{
		name: '后端开发',
		type: ESkillItemType.CATEGORY,
		skills: [{ name: 'Node.js' }, { name: 'Java' }, { name: '.NET' }, { name: '其他编程语言' }]
	},
	{
		name: '数据库（如MySQL，MongoDB等）',
		type: ESkillItemType.SKILL
	},
	{
		name: '版本控制（如Git等）',
		type: ESkillItemType.SKILL
	},
	{
		name: 'DevOps相关技能（如Docker，CI/CD等）',
		type: ESkillItemType.SKILL
	}
];

export default {};
