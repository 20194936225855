import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sum } from 'lodash';

import { RootState } from '..';

import {
	PARAMS_ID,
	PARAMS_KEYWORD_KEY,
	PARAMS_PAGE_INDEX_KEY,
	PARAMS_SORT_KEY,
	PARAMS_STUDY_CENTER_FILE_TYPE_KEY,
	PARAMS_STUDY_CENTER_RESOURCE_TYPE_KEY,
	PARAMS_SYLLABUS_TAB_VALUE,
	PRAMS_STUDY_CENTER_PROGRAM_DETAIL_TAB_VALUE
} from '@/constants/searchParams';
import { fetchMaterialTagsForStudy, fetchMaterialsForStudy } from '@/services/material';
import { fetchStudyProgram } from '@/services/study';

const PAGE_SIZE = 24;

interface StudyProgramMaterialsParams {
	[PARAMS_ID]?: string;
	[PARAMS_PAGE_INDEX_KEY]?: string;
	[PARAMS_STUDY_CENTER_RESOURCE_TYPE_KEY]?: string;
	[PARAMS_STUDY_CENTER_FILE_TYPE_KEY]?: string;
	[PARAMS_KEYWORD_KEY]?: string;
	[PARAMS_SORT_KEY]?: string;
}

export interface IRefreshProgramMaterialsPayload {
	trainingId?: string;
	params?: StudyProgramMaterialsParams;
}

interface IStudyProgramSyllabusFilter {
	module?: string;
	type?: string;
	status?: string;
	required?: string;
}

interface IStudyProgramMaterial {
	data?: StudyProgram;
	materials?: TPagination<IFetchMaterial>;
	materialTags?: IMaterialTypes[];
	totalNum?: number;
}

interface StudyProgramState {
	tab: PRAMS_STUDY_CENTER_PROGRAM_DETAIL_TAB_VALUE;
	filter: IStudyProgramSyllabusFilter;
	syllabusTab?: PARAMS_SYLLABUS_TAB_VALUE;
	data?: StudyProgram;
	material: IStudyProgramMaterial;
}

export const refreshStudyProgram = createAsyncThunk(
	'study/refreshStudyProgram',
	async (payload: string): Promise<StudyProgram> => {
		const data = await fetchStudyProgram(payload);
		return data;
	}
);

export const refreshStudyProgramMaterials = createAsyncThunk(
	'study/refreshStudyProgramMaterials',
	async (payload: IRefreshProgramMaterialsPayload): Promise<IStudyProgramMaterial> => {
		let data: StudyProgram | undefined;
		if (!payload.trainingId) {
			data = await fetchStudyProgram(payload.params![PARAMS_ID] || '');
		} else {
			data = undefined;
		}
		if (payload.trainingId || (data && data.training)) {
			const result = await fetchMaterialsForStudy({
				pageIndex: parseInt(payload.params![PARAMS_PAGE_INDEX_KEY] || '1', 10),
				pageSize: PAGE_SIZE,
				trainingId: payload.trainingId || data?.training,
				resourceType: payload.params![PARAMS_STUDY_CENTER_RESOURCE_TYPE_KEY],
				fileType: payload.params![PARAMS_STUDY_CENTER_FILE_TYPE_KEY],
				keyword: payload.params![PARAMS_KEYWORD_KEY],
				sort: payload.params![PARAMS_SORT_KEY]
			});
			let tagResult: IMaterialTypes[] = [];
			try {
				tagResult = await fetchMaterialTagsForStudy({
					trainingId: payload.trainingId || data?.training,
					fileType: payload.params![PARAMS_STUDY_CENTER_FILE_TYPE_KEY],
					keyword: payload.params![PARAMS_KEYWORD_KEY]
				});
			} catch (e) {
				tagResult = [];
			}
			return {
				data,
				materials: result,
				materialTags: tagResult,
				totalNum: sum(tagResult?.map(tag => tag.count)) || 0
			};
		}
		return {
			data: undefined,
			materials: undefined,
			materialTags: [],
			totalNum: 0
		};
	}
);

const initialState: StudyProgramState = {
	tab: PRAMS_STUDY_CENTER_PROGRAM_DETAIL_TAB_VALUE.HOME,
	filter: {},
	material: {}
};

export const studyProgramSlice = createSlice({
	name: 'studyProgram',
	initialState,
	reducers: {
		patchStudyProgramFilter: (state, action: PayloadAction<IStudyProgramSyllabusFilter>) => {
			state.filter = {
				...state.filter,
				...action.payload
			};
		},
		updateStudyProgramTab: (
			state,
			action: PayloadAction<PRAMS_STUDY_CENTER_PROGRAM_DETAIL_TAB_VALUE>
		) => {
			state.tab = action.payload;
		},
		updateStudyProgramSyllabusTag: (
			state,
			action: PayloadAction<PARAMS_SYLLABUS_TAB_VALUE>
		) => {
			state.syllabusTab = action.payload;
		}
	},
	extraReducers: builder => {
		builder.addCase(
			refreshStudyProgramMaterials.fulfilled,
			(state, action: PayloadAction<IStudyProgramMaterial>) => {
				if (action.payload.data) {
					state.data = action.payload.data;
				}
				state.material = {
					materials: action.payload.materials,
					materialTags: action.payload.materialTags,
					totalNum: action.payload.totalNum
				};
			}
		);
		builder.addCase(
			refreshStudyProgram.fulfilled,
			(state, action: PayloadAction<StudyProgram>) => {
				state.data = action.payload;
			}
		);
	}
});

export const { updateStudyProgramTab, updateStudyProgramSyllabusTag, patchStudyProgramFilter } =
	studyProgramSlice.actions;

export const selectStudyProgramState = (state: RootState) => state.studyProgram;
export default studyProgramSlice.reducer;
